<template>
    <footer class="footer has-cards">
        
        <div class="container">
            
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-6">
                    <div class="copyright">
                        <a href="mailto:info@pendograte.com">info@pendograte.com</a>
                        &copy; {{year}}
                        
                    </div>
                </div>
                <div class="col-md-6">
                    <ul class="nav nav-footer justify-content-end">
                        <li class="nav-item">
                            <a href="https://www.pendograte.com" class="nav-link" target="_blank" rel="noopener">Pendograte Systems</a>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'app-footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
<style>
</style>
